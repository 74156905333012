$light:#f8f9fa;
$baseBackground:$light;
$baseColor :#505050;
$wSidebar:250px;
$white:white;
$lightGray:#f8f9fe;

$bg-light: $white;

@mixin radius {
    border-radius: .43rem;
}
@mixin shadow {
    box-shadow: 0 4px 7px rgba(50, 50, 93, 0.11);
}

$primary :#4285f4;
$blue:#4b7bec;
$info:#33b5e5;
$success:#20c997;
$warning:#fdc53d;
$danger:#ff818d;
$grey:#6c757d;
$dark:#343a40;
$secondary:#6c757d;


/* colors cards */



/* colors light theme
$gray: #5f616d;
$lightGray: #f8f9fe;
$cardTitle: #6f717e;
$baseBackground: white;
$baseColor: #1d2029;
$switchSpinner: white;
$switch: #5f616d; */